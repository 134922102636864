<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Suporte</h1>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-success mb-3" @click="add()">
                                <font-awesome-icon icon="plus" /> Novo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="overlay dark" v-if="isLoading">
                                <font-awesome-icon icon="sync-alt" class="fa-3x fa-spin" />
                            </div>
                            <div class="card-body table-responsive p-0">
                                <table class="table table-striped table-valign-middle">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Status</th>
                                            <th>Assunto</th>
                                            <th>Aberto em</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="support in supports" :key="support.id" @click="open(support.id)">
                                            <td>{{ support.support.id }}</td>
                                            <td>
                                                <span class="badge" :style="`background-color: #${support.support.status.color}`">{{ support.support.status.name }}</span>
                                            </td>
                                            <td>{{ support.support.subject }}</td>
                                            <td>{{ new Date(support.created_at).toLocaleString() }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <Pagination @updateData="supports = $event" @isBusy="isBusy = $event" :url="url"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pagination from '../partials/Pagination.vue'
import { SUPPORT_INDEX } from "../../constants/api"

export default {
    name: 'Index',
    components: { Pagination },
    data() {
        return {
            supports: [],
            url: SUPPORT_INDEX,
            isBusy: true,
        }
    },
    methods: {

        add() {
            this.$router.push({ name: 'support.create'})
        },

        open(id) {
            this.$router.push({ name: 'support.show', params: { id:  id }})
        },
    }
}
</script>

<style scoped>

    tbody > tr {
        cursor: pointer;
    }

</style>